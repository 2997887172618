<template>
  <div class="section" id="marcas">
    <div class="container">
      <div class="row">
        <div class="col s12 spaceTop">
          <h3>Marcas</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="container" id="marcas">
    <div class="section">
      <div class="row">
        <div class="col s12 m3">
          <p>3M</p>
          <h4 class="center-align">A</h4>
          <p>ABB</p>
          <p>ADIR</p>
          <p>ALLEN BRADLEY</p>
          <p>ALPHA WIRE</p>
          <p>ALTECH CORP</p>
          <p>AMESA</p>
          <p>AMPHENOL</p>
          <p>AMPROBE</p>
          <p>ANBEC</p>
          <p>ANCLO</p>
          <p>ANSELL</p>
          <p>APEX TOOLS</p>
          <p>APOLLO</p>
          <p>APT</p>
          <p>ARDUINO</p>
          <p>ARROW HART</p>
          <p>ARSA</p>
          <p>ARTLINE</p>
          <p>ASCO NUMATICS</p>
          <p>AUER</p>
          <p>AUSTROMEX</p>
          <p>AUTOMATION DIRECT</p>
          <p>AUTONICS</p>
          <p>AVEN TOOLS</p>
          <p>AVENTICS</p>
          <p>AZOR</p>
          <h4 class="center-align">B</h4>
          <p>B&K PRECISION</p>
          <p>BACHARACH</p>
          <p>BACO</p>
          <p>BALDOR</p>
          <p>BALLUFF</p>
          <p>BANNER</p>
          <p>BARMESA</p>
          <p>BARRILITO</p>
          <p>BAUMER</p>
          <p>BEI SENSORS</p>
          <p>BELDEN</p>
          <p>BESSEY</p>
          <p>BIC</p>
          <p>BIN MASTER</p>
          <p>BLACK AND DECKER</p>
          <p>BOSCH</p>
          <p>BOSTIK</p>
          <p>BOSTITCH</p>
          <p>BOURNS</p>
          <p>BRADY</p>
          <p>BRASSO</p>
          <p>BRAUNKER</p>
          <p>BRIDGESTONE</p>
          <p>BROWNING</p>
          <p>BTCINO</p>
          <p>BULGIN</p>
          <p>BURNDY</p>
          <p>BUSSMANN</p>
          <h4 class="center-align">C</h4>
          <p>C&K COMPONENTS</p>
          <p>CABLOFIL</p>
          <p>CAMOZZI</p>
          <p>CARLO GAVAZZI</p>
          <p>CHAROFIL</p>
          <p>CHINT</p>
          <p>CKD</p>
          <p>CNTD</p>
          <p>COGNEX</p>
          <p>COILHOSE PNEUMATICS</p>
          <p>CONDULAC</p>
          <p>CONDUMEX</p>
          <p>CONTRINEX</p>
          <p>CONTROL TECHNIQUES</p>
          <p>COOPER</p>
          <p>COPAMEX</p>
          <p>CROSSLINE</p>
          <p>CROUSE HINDS</p>
          <p>CROUZET</p>
          <p>CURTIS</p>
          <p>CUTLER HAMMER</p>
          <h4 class="center-align">D</h4>
          <p>DAIKIN</p>
          <p>DAITO</p>
          <p>DAIWA DENGYO</p>
          <p>DANFOSS</p>
          <p>DATALOGIC</p>
          <p>DAYTON</p>
          <p>DELPHI</p>
          <p>DELTA</p>
          <p>DESTACO</p>
        </div>
        <div class="col s12 m3">
          <p>DEWIT</p>
          <p>DIALIGHT</p>
          <p>DICA</p>
          <p>DICHTOMATIK</p>
          <p>DI-SORIC</p>
          <p>DIXON</p>
          <p>DREMEL</p>
          <p>DUFF NORTON</p>
          <p>DWYER</p>
          <p>DYNISCO</p>
          <h4 class="center-align">E</h4>
          <p>E.DOLD & SONS</p>
          <p>E.MC</p>
          <p>EASTMAN</p>
          <p>EATON</p>
          <p>ECLIPSE</p>
          <p>ELASTIMOLD</p>
          <p>ELDON</p>
          <p>ELESTA</p>
          <p>ELMO RITHCI</p>
          <p>EMERSON</p>
          <p>ENCODER PRODUCTS</p>
          <p>ENERGIZER</p>
          <p>EPSON</p>
          <p>ERICO</p>
          <p>ESTIC</p>
          <p>EUCHNER</p>
          <p>EUROTHERM</p>
          <p>EVANS</p>
          <p>EXTECH INSTRUMENTS</p>
          <h4 class="center-align">F</h4>
          <p>FABCO</p>
          <p>FAG</p>
          <p>FANDELI</p>
          <p>FANUC</p>
          <p>FERRAZ SHAWMUT</p>
          <p>FESTO</p>
          <p>FINDER</p>
          <p>FIRESTONE</p>
          <p>FISHER</p>
          <p>FLIR</p>
          <p>FLUKE</p>
          <p>FOSEY</p>
          <p>FREESCALE</p>
          <p>FUJI ELECTRIC</p>
          <p>FUJIKIN</p>
          <p>FUJITSU</p>
          <h4 class="center-align">G</h4>
          <p>GARDNER DENVER</p>
          <p>GARLOCK</p>
          <p>GAST</p>
          <p>GATES</p>
          <p>GE</p>
          <p>GENERAL CABLE</p>
          <p>GOULD</p>
          <p>GRUNDFOS</p>
          <h4 class="center-align">H</h4>
          <p>HAMMOND MANUFACTURING</p>
          <p>HANGYOUNG NUX</p>
          <p>HARTING</p>
          <p>HARTMAN</p>
          <p>HASKEL</p>
          <p>HELLERMAN TYTON</p>
          <p>HELUKABEL</p>
          <p>HENKEL</p>
          <p>HIKVISION</p>
          <p>HIMEL</p>
          <p>HIOKI</p>
          <p>HIRSCHMANN</p>
          <p>HITACHI</p>
          <p>HNC ELECTRIC</p>
          <p>HOFFMAN</p>
          <p>HONDA</p>
          <p>HONEYWELL</p>
          <p>HUBBELL</p>
          <h4 class="center-align">I</h4>
          <p>IDE</p>
          <p>IDEC</p>
          <p>IFM ELECTRONIC</p>
          <p>IGUS</p>
          <p>IKO</p>
          <p>INA</p>
          <p>INFRA</p>
          <p>INTRAMAT</p>
          <p>INVERTEK DRIVES</p>
          <p>IRWIN</p>
          <p>lUSA</p>
          <h4 class="center-align">J</h4>
          <p>JOHNSON ELECTRIC</p>
        </div>
        <div class="col s12 m3">
          <h4 class="center-align">K</h4>
          <p>KARCHER</p>
          <p>KB ELECTRONICS</p>
          <p>KBC</p>
          <p>KEMET</p>
          <p>KEYENCE</p>
          <p>KEYSIGHT TECHNIQUES</p>
          <p>KLEIN TOOLS</p>
          <p>KNIPEX</p>
          <p>KOGANEI</p>
          <p>KONSEI</p>
          <p>KORES</p>
          <p>KROM SCHROEDER</p>
          <p>KYOWA</p>
          <h4 class="center-align">L</h4>
          <p>LAPPKABEL</p>
          <p>LEESON</p>
          <p>LEGRAND</p>
          <p>LENZE</p>
          <p>LEUZE</p>
          <p>LEVITON</p>
          <p>LINKBELT</p>
          <p>LITTELFUSE</p>
          <p>LOCTITE</p>
          <p>LS</p>
          <p>LUBE</p>
          <p>LUMBERG AUTOMATION</p>
          <h4 class="center-align">M</h4>
          <p>MAC</p>
          <p>MAE</p>
          <p>MAGG</p>
          <p>MAKITA</p>
          <p>MARATHON</p>
          <p>MARTIN</p>
          <p>MAXON</p>
          <p>MC</p>
          <p>MEDIDORES INT. ROCHESTER</p>
          <p>MEGAMEX</p>
          <p>METRON</p>
          <p>MGG</p>
          <p>MICRO AUTOMATION</p>
          <p>MIKELS</p>
          <p>MILWAUKEE</p>
          <p>MITSUBISHI</p>
          <p>MITUTOYO</p>
          <p>MOBIL</p>
          <p>MOELLER</p>
          <p>MOLEX</p>
          <p>MONROE</p>
          <p>MSA SAFETY</p>
          <p>MUELLER ELECTRIC</p>
          <p>MURR ELEKTRONIK</p>
          <h4 class="center-align">N</h4>
          <p>NACHI</p>
          <p>NATIONAL INSTRUMENTS</p>
          <p>NEUTRIK</p>
          <p>NICE</p>
          <p>NICHIFU</p>
          <p>NICHOLSON</p>
          <p>NIDE</p>
          <p>NIDEC</p>
          <p>NIHON</p>
          <p>NISSEI CORP</p>
          <p>NITTO</p>
          <p>NORD</p>
          <p>NORDSON</p>
          <p>NORGREN</p>
          <p>NORTON</p>
          <p>NSK</p>
          <p>NTN</p>
          <p>NUMATICS</p>
          <h4 class="center-align">O</h4>
          <p>OHMITE</p>
          <p>OMEGA</p>
          <p>OMRON</p>
          <p>OPTIBELT</p>
          <p>ORIENTAL MOTOR</p>
          <p>ORION</p>
          <p>OSRAM</p>
          <h4 class="center-align">P</h4>
          <p>PANASONIC</p>
          <p>PANDUIT</p>
          <p>PAPER MATE</p>
          <p>PARKER</p>
          <p>PATLITE</p>
          <p>PEDROLLO</p>
          <p>PELIKAN</p>
          <p>PEPPERL & FUCHS</p>
          <p>PERFECT</p>
        </div>
        <div class="col s12 m3">
          <p>PHILLIPS LIGHTING</p>
          <p>PHOENIX CONTACT</p>
          <p>PILZ</p>
          <p>POLIFLEX</p>
          <p>POMONA ELECTRONICS</p>
          <p>POST IT</p>
          <p>PRECISION BRAND</p>
          <p>PRETUL</p>
          <p>PRITT</p>
          <p>PROFACE BY SCHNEIDER</p>
          <p>PULS</p>
          <p>PULSE ELECTRONICS</p>
          <h4 class="center-align">Q</h4>
          <p>QUALTEK</p>
          <h4 class="center-align">R</h4>
          <p>RAWELT</p>
          <p>RED LION CONT.</p>
          <p>RESISTOL</p>
          <p>RITE-HITE</p>
          <p>RITTAL</p>
          <p>ROBERT BOSCH</p>
          <p>RS PRO</p>
          <h4 class="center-align">S</h4>
          <p>SANYO DENKI</p>
          <p>SCHANFFNER</p>
          <p>SCHMERSAL</p>
          <p>SCHNEIDER ELECTRIC</p>
          <p>SCOTCH</p>
          <p>SCOTCH BRITE</p>
          <p>SEAL MASTER</p>
          <p>SENSATA</p>
          <p>SEW EURODRIVE</p>
          <p>SICK</p>
          <p>SIEMENS</p>
          <p>SKF</p>
          <p>SKIL</p>
          <p>SMF</p>
          <p>SOLA BASIC</p>
          <p>SOLA HD</p>
          <p>SQUARE D</p>
          <p>STANLEY</p>
          <p>STEINEL</p>
          <p>SUMITOMO</p>
          <h4 class="center-align">T</h4>
          <p>TDK LAMBDA</p>
          <p>TE CONNECTIVITY</p>
          <p>TECNOLITE</p>
          <p>TELEMECANIQUE</p>
          <p>THK</p>
          <p>THOMAS & BETTS</p>
          <p>THORSMAN</p>
          <p>TIMKEN</p>
          <p>TKD</p>
          <p>TOSHIBA</p>
          <p>TRI-TRONICS</p>
          <p>TRUPER</p>
          <p>TSUBAKI</p>
          <p>TUK</p>
          <p>TURCK</p>
          <p>TYCO ELECTRONICS</p>
          <h4 class="center-align">U</h4>
          <p>URREA</p>
          <h4 class="center-align">V</h4>
          <p>VAYREMEX</p>
          <p>VIAKON</p>
          <p>VICKERS</p>
          <p>VISHAY</p>
          <p>VMECA</p>
          <p>VOLTECH</p>
          <h4 class="center-align">W</h4>
          <p>WAGO</p>
          <p>WARRICK CONTROLS</p>
          <p>WATLOW</p>
          <p>WATSON MCDANIEL</p>
          <p>WEG</p>
          <p>WEIDMULLER</p>
          <p>WEILER</p>
          <p>WELLER</p>
          <p>WEST INSTRUMENTS</p>
          <p>WINTERS</p>
          <p>WOODHEAD</p>
          <p>WURTH</p>
          <h4 class="center-align">X</h4>
          <p>XYCO</p>
          <p>XYLEM</p>
          <h4 class="center-align">Y</h4>
          <p>YPC</p>
          <h4 class="center-align">Z</h4>
          <p>ZEBRA</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Brands",

  beforeCreate: function () {
    this.$emit("setPageInfo", "Marcas", "");
    this.$emit("showMenu", true);
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/custom.scss";
#marcas {
  font-weight: bold;
  padding: 0rem !important;
  .spaceTop {
    padding-top: 3.25rem;
  }
  @media (min-width: 50rem) and (max-width: 74rem) {
    .spaceTop {
      padding-top: 8.25rem;
    }
  }
  @media (max-width: 62rem) {
    .spaceTop {
      padding-top: 3.25rem !important;
    }
  }
  @media (min-width: 35rem) and (max-width: 49rem) {
    .spaceTop {
      padding-top: 10.25rem;
    }
  }
  @media (min-width: 28rem) and (max-width: 35rem) {
    .spaceTop {
      padding-top: 12.25rem;
    }
  }
  .light {
    color: $blueInitial;
  }
  h4 {
    background-color: $blueInitial;
    color: $white;
    margin: 0rem !important;
    padding: 0.5rem 0;
  }
}
</style>
